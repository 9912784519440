import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const LineTextLine = makeShortcode("LineTextLine");
const SimpleReactLightbox = makeShortcode("SimpleReactLightbox");
const LightBox = makeShortcode("LightBox");
const Container = makeShortcode("Container");
const Row = makeShortcode("Row");
const Col = makeShortcode("Col");
const ImageBed = makeShortcode("ImageBed");
const Picture = makeShortcode("Picture");
const AndManyMore = makeShortcode("AndManyMore");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title={props.pageContext.frontmatter.title} description={props.pageContext.frontmatter.description} mdxType="Seo" />
    <LineTextLine title={props.pageContext.frontmatter.title} mdxType="LineTextLine" />
    <SimpleReactLightbox mdxType="SimpleReactLightbox">
  <LightBox mdxType="LightBox">
    <Container mdxType="Container">
      <Row mdxType="Row">
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Simon" modelName="Simon Dmitriev" place="Moscow" filename="simon-looks-down.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Nikolay" modelName="Nikolay Bondarenko" place="Moscow" filename="Nikolay-photography-by-andrey-bond.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Stas Stanis" modelName="Stas Stanis, actor" place="Moscow" filename="stas-stanis-photography-by-andrey-bond.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
      </Row>
      <Row mdxType="Row">
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Jorge" modelName="Jorge Valdez" place="Barcelona" filename="Jorge-1-2.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Abdel" modelName="Abdelkhalek Jnah" place="Barcelona" filename="Abdel_photography_by_Andrey_Bond-5.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Luca" modelName="Luca De Flaviis" place="Barcelona" filename="Luca_photography_by_Andrey_Bond-2.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Vasiliy" modelName="Vasiliy Lebedev" place="Barcelona" filename="Vasiliy.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
        <Col p5="true" mdxType="Col">
          <ImageBed mdxType="ImageBed">
            <Picture name="Aleksander" modelName="Aleksander Stepanenko" place="Barcelona" filename="stepanenko_ap_andrey_bondarenko.jpg" mdxType="Picture" />
          </ImageBed>
        </Col>
      </Row>
    </Container>
  </LightBox>
    </SimpleReactLightbox>
    <AndManyMore mdxType="AndManyMore" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      